import React from "react"
import { graphql } from "gatsby"

import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/sources/hero"
import About from "components/sections/sources/about"
import Featured from "components/sections/sources/featured"
import Explore from "components/sections/sources/explore"


import Trial from "components/common/trial"
import { seoTitleSuffix } from "config"

const SourcesPage = ({ data }) => {
  const { frontmatter } = data.sources.edges[0].node

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: frontmatter.useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
     <Layout headerContent={data.header.edges} footerContent={data.footer.edges} lang="fr">
        <SEO
          frontmatter={frontmatter}
          lang="fr"
        />
        <Hero content={data.hero.edges} />
        <About content={data.about.edges} />
        <Featured content={data.featured.edges} />
        <Explore content={data.explore.edges} />
        <div className="gradient">

        </div>
        
      </Layout>
    </GlobalStateProvider>
  )
}


export default SourcesPage

export const pageQuery = graphql`
  {
    sources: allMdx(filter: { fileAbsolutePath: { regex: "/sources/fr/sources/" } }) {
      edges {
        node {
          frontmatter {
            title
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/sources/fr/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            teamPhoto {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
            mdxbgImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
          }
        }
      }
    }
    about: allMdx(filter: { fileAbsolutePath: { regex: "/sources/fr/about/" } }) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            sectionTitle
            sectionSubtitle
            text
            mdxImage {
              childImageSharp {
                gatsbyImageData(width: 50, layout: FIXED, quality: 90)
              }
            }
          }
        }
      }
    }
    featured: allMdx(filter: { fileAbsolutePath: { regex: "/sources/fr/featured/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          exports {
            items {
              title
              description
              logo {
                childImageSharp {
                  gatsbyImageData(height: 70, layout: FIXED, quality: 90)
                }
              }
            }
          }
        }
      }
    }
    explore: allMdx(filter: { fileAbsolutePath: { regex: "/sources/fr/explore/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          exports {
            items {
              description
              label
              review
              category
              logo {
                childImageSharp {
                  gatsbyImageData(quality: 90)
                }
              }
            }
          }
        }
      }
    }
    footer: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/footer/" } }) {
      edges {
        node {
          body
          frontmatter {
            label
            title
            titlePrefix
            subtitle
            subtitlePrefix
            buttonBookingText
            buttonBookingLink
            buttonSignupText
            buttonSignupLink
            copyRight
          }
          exports {
            items {
              name
              links {
                text
                link
                external
              }
            }
          }
        }
      }
    }
    header: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/header/" } }) {
      edges {
        node {
          frontmatter {
            demoButtonText
            demoButtonLink
            registerButtonText
            registerButtonLink
            bookingButtonText
            bookingButtonLink
          }
          exports {
            items {
              name
              modal {
                left {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                }
                right {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                  media {
                    name
                    photo
                    alt
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
